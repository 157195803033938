<template>
  <div class="panel">
    <el-form
      label-position="top"
      size="mini"
      ref="outletform"
      :model="outletform"
      :rules="rules"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="Үйлчилгээ" prop="service_list">
            <el-select
              v-model="outletform.service_list"
              placeholder="Select"
              class="dblock"
              clearable
              filterable
              multiple
              @change="onChangeServices"
              :disabled="
                !mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'menu_editor'
                ])
              "
            >
              <el-option value="food13" key="food13" label="Тест"></el-option>
              <el-option
                v-for="(service, indexService) in servicesList"
                :label="service.name_mon"
                :value="service.name_eng"
                :key="indexService"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Нэр монгол"
            prop="full_name_mon"
            v-if="
              mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin', 'menu_editor'])
            "
          >
            <el-input v-model="outletform.full_name_mon"></el-input>
          </el-form-item>
          <el-form-item
            label="Нэр англи"
            prop="full_name_eng"
            v-if="
              mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin', 'menu_editor'])
            "
          >
            <el-input v-model="outletform.full_name_eng"> </el-input>
          </el-form-item>
          <el-form-item
            label="Гэрээ байгуулсан эсэх"
            v-if="!mArrayIncludes(role, ['finance', 'other', 'ops'])"
          >
            <el-checkbox v-model="outletform.is_verified">
              Гэрээ байгуулсан эсэх
            </el-checkbox>
          </el-form-item>
          <el-form-item
            label="Төлөв"
            v-if="!mArrayIncludes(role, ['finance', 'other'])"
          >
            <el-checkbox v-model="outletform.is_active">
              Нээлттэй эсэх
            </el-checkbox>
          </el-form-item>
          <el-form-item
            label="Харагдуулах"
            v-if="
              mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin', 'menu_editor'])
            "
          >
            <el-checkbox v-model="outletform.is_draft">
              Нуух эсэх
            </el-checkbox>
          </el-form-item>
          <el-form-item
            label="Онцлох"
            v-if="
              mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin', 'menu_editor'])
            "
          >
            <el-checkbox v-model="outletform.is_special">
              Онцлох эсэх
            </el-checkbox>
          </el-form-item>
          <el-form-item
            label="Нүүр хуудсанд онцлох"
            v-if="
              mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin', 'menu_editor'])
            "
          >
            <el-checkbox v-model="outletform.is_special_home">
              Нүүр хуудсанд онцлох эсэх
            </el-checkbox>
          </el-form-item>
          <el-form-item
            label="Ebarimt-г манайх гаргах эсэх"
            v-if="!mArrayIncludes(role, ['client_care', 'ops', 'TL', 'CC'])"
          >
            <el-checkbox
              :disabled="
                !mArrayIncludes(role, [
                  'boss',
                  'finance',
                  'ST',
                  'SM',
                  'admin',
                  'menu_editor'
                ])
              "
              v-model="outletform.ebarimt"
            >
              Ebarimt
            </el-checkbox>
          </el-form-item>
          <el-form-item label="Урьдчилсан захиалга">
            <el-checkbox
              v-model="outletform.is_preOrder"
              :disabled="outletform.is_pickup"
            >
              Урьдчилсан захиалга
            </el-checkbox>
          </el-form-item>
          <el-form-item v-if="outletform.is_preOrder">
            <el-switch
              v-model="outletform.is_preorder_by_date"
              size="small"
              active-text="Он сар сонгох"
              inactive-text="Өдөрөө оруулах"
            />
          </el-form-item>
          <!-- {{ outletform }} -->
          <el-form-item
            v-if="!outletform.is_preorder_by_date && outletform.is_preOrder"
            label="Урьдчилсан захиалгын өдөр"
          >
            <el-input-number
              :max="15"
              :min="1"
              v-model="outletform.preorder_days"
              :disabled="!outletform.is_preOrder"
            >
            </el-input-number>
          </el-form-item>
          <el-form-item
            v-else-if="outletform.is_preOrder"
            label="Урьдчилсан захиалгын өдөр"
          >
            <el-date-picker
              style="width: auto"
              v-model="outletform.preorder_start_date"
              type="date"
              :disabled="!outletform.is_preOrder"
              placeholder="Start Date"
              format="yyyy/MM/dd"
            />
            <el-date-picker
              style="margin-top: 10px; width: auto"
              v-model="outletform.preorder_end_date"
              type="date"
              :disabled="!outletform.is_preOrder"
              placeholder="End Date"
              format="yyyy/MM/dd"
            />
          </el-form-item>
          <el-form-item label="Одоо захиалга">
            <el-checkbox v-model="outletform.is_asap">
              Одоо захиалга
            </el-checkbox>
          </el-form-item>
          <el-form-item label="Зэрэглэл" prop="client_category">
            <el-select
              size="mini"
              v-model="outletform.client_category"
              placeholder="Суртчилгааны байрлал сонгоно уу"
            >
              <el-option label="A" value="A"></el-option>
              <el-option label="B" value="B"></el-option>
              <el-option label="+B" value="+B"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Хүргэлтийн төлбөр үнэгүй эсэх"
            v-if="!mArrayIncludes(role, ['finance', 'other', 'ops'])"
          >
            <el-checkbox v-model="outletform.is_delivery_free">
              Хүргэлтийн төлбөр үнэгүй эсэх
            </el-checkbox>
          </el-form-item>
          <!-- <el-form-item
            label="Толгой харилцагчтай эсэх"
            v-if="!mArrayIncludes(role, ['ops', 'TL', 'CC'])"
          >
            <el-checkbox
              v-model="outletform.is_child"
              :disabled="!mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin'])"
            >
              Толгой харилцагчтай эсэх
            </el-checkbox>
          </el-form-item> -->
          <el-form-item>
            <el-switch
              v-if="
                mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'client_care',
                  'menu_editor'
                ])
              "
              v-model="selectBadge"
              active-text="Бадже харах"
              inactive-text="Бадже харахгүй"
            >
            </el-switch>
          </el-form-item>
          <el-form-item
            v-if="
              this.selectBadge == true &&
                mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'client_care',
                  'menu_editor'
                ])
            "
            label="Бадже өнгө"
            prop="color"
          >
            <el-color-picker
              v-model="outletFormBadge.color"
              :disabled="
                !mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'menu_editor'
                ])
              "
            ></el-color-picker>
          </el-form-item>
          <el-form-item
            v-if="
              this.selectBadge == true &&
                mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'client_care',
                  'menu_editor'
                ])
            "
            label="Бадже сонгоно уу"
            prop="name_mon"
          >
            <el-select
              filterable
              v-model="outletFormBadge.name_mon"
              size="mini"
              placeholder="Бадже сонгох"
              @change="changeBadge()"
              class="dblock"
            >
              <el-option
                v-for="(item, indexBadge) in BadgesList"
                :key="indexBadge"
                :label="item.key"
                :value="item.name_mon"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="
              this.selectBadge == true &&
                mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'client_care',
                  'menu_editor'
                ])
            "
            label="Үргэлжлэх хугацаа"
            prop="badge_start_date"
          >
            <el-date-picker
              type="date"
              placeholder="Эхлэх огноо"
              v-model="outletform.badge_start_date"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            v-if="
              this.selectBadge == true &&
                mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'client_care',
                  'menu_editor'
                ])
            "
            prop="badge_end_date"
          >
            <el-date-picker
              type="date"
              placeholder="Дуусах огноо"
              v-model="outletform.badge_end_date"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="Хүргэлттэй эсэх">
            <el-checkbox
              v-model="outletform.is_delivery"
              :disabled="!mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin'])"
            >
              Хүргэлттэй эсэх
            </el-checkbox>
          </el-form-item> -->
          <el-form-item label="Очиж авах">
            <el-checkbox
              v-model="outletform.is_pickup"
              :disabled="
                !mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'menu_editor'
                ])
              "
              @change="pickUp(outletform.is_pickup)"
            >
              Очиж авах
            </el-checkbox>
          </el-form-item>
          <el-form-item
            label="Харилцагч өөрөө хүргэх эсэх"
            v-if="!mArrayIncludes(role, ['finance', 'other'])"
          >
            <el-checkbox v-model="outletform.is_delivery_by_outlet">
              Харилцагч өөрөө хүргэх эсэх
            </el-checkbox>
          </el-form-item>
          <div>
            <el-form-item
              label="Харилцагчийн хүргэх хугацаа"
              prop="outlet_delivery_time"
            >
              <el-input-number
                v-model="outletform.outlet_delivery_time"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              ></el-input-number>
            </el-form-item>
          </div>
          <el-form-item
            label="Харилцагчийн хүргэлтийн шимтгэлийн дүн"
            prop="outlet_delivery_amount"
          >
            <el-input-number
              v-model="outletform.outlet_delivery_amount"
              :disabled="mArrayIncludes(role, ['finance', 'other'])"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            v-if="outletform.is_child === true"
            label="Толгой харилцагч сонгох"
          >
            <el-select v-model="outletform.parent" clearable filterable>
              <el-option
                v-for="(parent, indexParentOutlet) in parent_outlet_name"
                :label="parent.full_name_eng"
                :value="parent.full_name_eng"
                :key="indexParentOutlet"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Үйлчилгээний төрөл" v-if="isRenderNewDesign">
            <el-radio
              v-model="outletform.grocery_delivery_type"
              label="branded_goods"
              >Нэрийн бараа</el-radio
            >
            <el-radio
              v-model="outletform.grocery_delivery_type"
              label="chain_store"
              >Сүлжээ дэлгүүр</el-radio
            >
          </el-form-item>
          <el-form-item
            label="Эрэмбэ"
            v-if="outletform.grocery_delivery_type === 'chain_store'"
          >
            <el-tree
              :data="sortData"
              draggable
              :allow-drop="allowDrop"
              :allow-drag="allowDrag"
              style="display: flex; flex-direction: column; gap: 10px"
            >
              <span slot-scope="{ data }"
                >{{ data.sort_grocery_number }} -
                <el-tag>
                  {{ data.full_name_mon }}
                </el-tag>
              </span>
            </el-tree>
          </el-form-item>
          <el-form-item label="Шинэ хүргэлт эсэх" v-if="isRenderNewDesign">
            <el-checkbox
              v-model="outletform.is_new_delivery_order"
              @change="handleClick"
            >
              Шинэ хүргэлт эсэх
            </el-checkbox>
          </el-form-item>
          <el-form-item
            label="Харилцагчийн эрэмбэ"
            v-if="
              mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin', 'menu_editor'])
            "
          >
            <el-checkbox v-model="outletform.sortSkip">
              Харилцагчийн эрэмбийг идэвхжүүлэх эсэх
            </el-checkbox>
          </el-form-item>
          <el-form-item
            label="Тооцоо нийлэх давтамж"
            v-if="!mArrayIncludes(role, ['ops', 'TL', 'CC'])"
          >
            <el-radio-group
              v-model="outletform.settlement_count"
              size="small"
              :disabled="
                !mArrayIncludes(role, ['ST', 'SM', 'admin', 'menu_editor'])
              "
            >
              <el-radio-button label="1">Сард 1 удаа</el-radio-button>
              <el-radio-button label="2">Сард 2 удаа</el-radio-button>
              <el-radio-button label="4">Сард 4 удаа</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Тооцоо буулгах хугацаа (ажлын хоногоор)">
            <el-select v-model="outletform.settlement_delay_count">
              <el-option
                v-for="(day, dayIndex) in settlement_period_data"
                :key="dayIndex"
                :label="day.label"
                :value="day.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Order апп - (ашигладаг төхөөрөмж)">
            <el-select v-model="outletform.device_name">
              <el-option
                v-for="(device, deviceIndex) in orderDevice"
                :label="device.label"
                :value="device.value"
                :key="deviceIndex"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Утасны дугаарын жагсаалт">
            <el-table
              :data="outletform.phone_list"
              style="width: 100%"
              max-height="350"
            >
              <el-table-column prop="name" label="Албан тушаал" width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row.name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="phone" label="Утас" width="125">
                <template slot-scope="scope">
                  <el-input
                    size="small"
                    placeholder="99119911"
                    style="text-align:center"
                    type="number"
                    :min="8"
                    :max="8"
                    v-model.number="scope.row.phone"
                  ></el-input>
                </template> </el-table-column
              ><el-table-column prop="email" label="Цахим шуудан" width="180">
                <template slot-scope="scope">
                  <el-input
                    size="small"
                    placeholder="email@toktok.mn"
                    style="text-align:center"
                    type="text"
                    v-model="scope.row.email"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="Хаяг - апп" prop="address">
            <el-input
              type="textarea"
              v-model="outletform.address"
              :disabled="
                !mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'client_care',
                  'ops',
                  'menu_editor'
                ])
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="Хаяг англи  - апп" prop="address_eng">
            <el-input
              type="textarea"
              v-model="outletform.address_eng"
              :disabled="
                !mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'client_care',
                  'ops',
                  'menu_editor'
                ])
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="Жолоочид харагдах хаяг" prop="private_address">
            <el-input
              type="textarea"
              v-model="outletform.private_address"
              :disabled="
                !mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'client_care',
                  'ops',
                  'menu_editor'
                ])
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="Хүргэлтийн бүс" prop="description">
            <el-input
              v-model="outletform.description"
              type="textarea"
              :rows="2"
              :disabled="mArrayIncludes(role, ['finance', 'other', 'CC'])"
            ></el-input>
          </el-form-item>
          <el-form-item label="Хүргэлтийн бүс англи" prop="description_eng">
            <el-input
              v-model="outletform.description_eng"
              type="textarea"
              :rows="2"
              :disabled="mArrayIncludes(role, ['finance', 'other', 'CC'])"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Зассан шалтгаан"
            prop="desc"
            v-if="this.$route.query._id !== null"
          >
            <el-input
              v-model="outletform.desc"
              type="textarea"
              placeholder="Засах шалгаанаа бичнэ үү!!!"
              :rows="2"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Хүргэлтийн бүсийн нэр" prop="address">
            <el-input
              type="textarea"
              v-model="outletform.zone_name"
              :disabled="mArrayIncludes(role, ['finance', 'other', 'CC'])"
            ></el-input>
          </el-form-item>
          <el-form-item label="Зураг">
            <base64-upload
              v-if="outletform.img_url !== null && outletform.img_url !== ''"
              style="width: 100px; height: 100px"
              :imageSrc="outletform.img_url"
              @change="onChangeImage"
            ></base64-upload>
            <a v-else v-loading="loadUpload">
              <base64-upload
                :imageSrc="imageNull"
                @change="onChangeImage"
              ></base64-upload>
            </a>
            <el-input
              v-model="outletform.img_url"
              :disabled="
                !mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'menu_editor'
                ])
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="Лого">
            <base64-upload
              v-if="outletform.logo_url !== null && outletform.logo_url !== ''"
              style="width: 100px; height: 100px"
              :imageSrc="outletform.logo_url"
              @change="onChangePickUpImage"
            ></base64-upload>
            <a v-else v-loading="loadUpload">
              <base64-upload
                :imageSrc="imageLogo"
                @change="onChangePickUpImage"
              ></base64-upload>
            </a>
          </el-form-item>
          <el-form-item
            label="Үндсэн ангилал"
            size="small"
            v-if="
              mArrayIncludes(role, [
                'boss',
                'ST',
                'SM',
                'admin',
                'client_care',
                'menu_editor'
              ])
            "
            prop="main_category_mon"
          >
            <el-select
              v-model="outletform.main_category_mon"
              multiple
              filterable
              placeholder="Select"
              class="dblock"
              @change="categoryChange()"
              :disabled="
                !mArrayIncludes(role, [
                  'boss',
                  'ST',
                  'SM',
                  'admin',
                  'menu_editor'
                ])
              "
            >
              <el-option
                v-for="(item, itemIndex) in mainCategoryList"
                :key="itemIndex"
                :label="item.name_mon"
                :value="item.name_mon"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Дотоод ангилал"
            size="small"
            v-if="
              mArrayIncludes(role, [
                'boss',
                'ST',
                'SM',
                'admin',
                'client_care',
                'menu_editor'
              ])
            "
            prop="menu_categories"
          >
            <draggable
              :list="outletform.menu_categories"
              class="list-group"
              ghost-class="ghost"
              :move="checkMove"
              @start="dragging = true"
              @end="dragging = false"
            >
              <el-tag
                class="list-group-item"
                v-for="(tag, indexTag) in outletform.menu_categories"
                :disable-transitions="true"
                :key="indexTag"
                type="info"
                closable
                @close="handleCloseMenuCategoryDrag(tag)"
              >
                {{ tag.menu_category_mon }}
              </el-tag>
            </draggable>
            <el-select
              v-model="outletform.menu_categories"
              multiple
              value-key="menu_category_mon"
              filterable
              placeholder="хоол сонгох"
            >
              <el-option-group
                v-for="(category, indexMenuCategory) in menuCategoryList"
                :key="indexMenuCategory"
                :label="category.service.toUpperCase()"
              >
                <el-option
                  v-for="(item, index) in category.options"
                  :key="index"
                  :label="item.menu_category_mon"
                  :value="item"
                >
                  <span style="float: left">{{ item.menu_category_mon }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.service
                  }}</span>
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Хамааралтай харилцагч сонгох"
            size="small"
            v-if="
              mArrayIncludes(role, [
                'boss',
                'ST',
                'SM',
                'admin',
                'client_care',
                'menu_editor'
              ])
            "
          >
            <el-select
              v-model="outletform.child_outlets"
              multiple
              value-key="outlet_name"
              filterable=""
              remote=""
              reserve-keyword
              placeholder="Please enter a keyword"
            >
              <el-option
                v-for="(outlet, indexOutlet) in parent_outlet_name"
                :key="indexOutlet"
                :label="outlet.outlet_name"
                :value="outlet"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Харилцагч хариуцсан ажилтан"
            prop="owner_data"
            v-if="!mArrayIncludes(role, ['ops', 'TL', 'CC'])"
          >
            <el-select
              v-model="outletform.owner_data"
              placeholder="Хариуцагч"
              value-key="owner_name"
            >
              <el-option
                v-for="(item, indexOwner) in ownerList"
                :key="indexOwner"
                :label="item.owner_name"
                :value="item"
                :disabled="
                  !mArrayIncludes(role, [
                    'boss',
                    'ST',
                    'SM',
                    'admin',
                    'menu_editor'
                  ])
                "
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="!mArrayIncludes(role, ['ops', 'TL', 'CC'])"
            prop="owner_data.phone"
            label="Харилцагч хариуцсан менежерийн утасны дугаар"
          >
            <el-input disabled v-model="outletform.owner_data.phone"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-button type="success" size="medium" @click="goPartnerAddress"
        >Үргэлжлүүлэх</el-button
      >
    </el-form>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import service from "@/helpers/services.js";
import Base64Upload from "vue-base64-upload";
import { getUserName, getGroups } from "../utils/auth";
import { arrayIncludes, checkProperty } from "../helpers/helper";
export default {
  name: "AddPartner",
  components: {
    Base64Upload,
    draggable
  },
  created() {
    const payload = {
      includeFields: ["full_name_eng", "outlet_name", "service_list"],
      is_verified: true,
      pageSize: 10000,
      fromRecord: 0
    };
    if (this.$route.query._id !== null) {
      this.getOutletById(this.$route.query._id);
      this.openFullScreenLoader(true);
    }
    service.getOutlets(payload).then(data => {
      this.parent_outlet_name = data.data.data;
    });
    this.getSearchCategories();
    this.getBadges();
    this.getOwnerList();
    this.getPartnersForGrocerySort();
  },
  mounted() {
    this.getMainCategories();
    getGroups().then(data => {
      this.role = data;
    });
  },
  data() {
    return {
      isRenderNewDesign: false,
      is_new_delivery: false,
      sortData: [],
      draggingNode: null,
      dropTargetNode: null,
      loading: null,
      parent_outlet_name: [],
      enabled: true,
      dragging: false,
      loadUpload: false,
      loaderUpload: false,
      imageNull: "none",
      imageLogo: "none",
      servicesList: [],
      service: "",
      allServices: [],
      mainCategoryList: [],
      searchCategoryList: [],
      mainCategoryEngList: [],
      value: "",
      drawShow: false,
      tempData: [
        { label: "test1" },
        { label: "test2" },
        { label: "test3" },
        { label: "test4" },
        { label: "test5" }
      ],
      orderDevice: [
        {
          label: "Windows(64bit)",
          value: "Windows(64bit) - TokTok order app татах"
        },
        {
          label: "Tablet​",
          value: "Tablet - Order.toktok.mn сайтаар орох"
        },
        {
          label: "Гар утас​​​",
          value: "Гар утас - Order.toktok.mn сайтаар орох​​"
        },
        {
          label: "Macbook​",
          value: "Mac - Order.toktok.mn сайтаар орох​​​​"
        },
        {
          label: "Бусад​​​​​",
          value: "Бусад - Order.toktok.mn сайтаар орох"
        }
      ],
      rules: {
        service: [
          {
            required: true,
            message: "Үйлчилгээний төрөл сонгоогүй байна.",
            trigger: "change"
          }
        ],
        service_list: [
          {
            required: true,
            message: "Үйлчилгээний төрөл сонгоогүй байна.",
            trigger: "change"
          }
        ],
        full_name_mon: [
          {
            required: true,
            message: "Харилцагчийн монгол нэрийг оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        full_name_eng: [
          {
            required: true,
            message: "Харилцагчийн англи нэрийг оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: true,
            message: "Харилцагчийн утасны дугаарыг оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        address: [
          {
            required: true,
            message: "Харилцагчийн хаягийг оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Харилцагчийн хүргэлтийн бүсийг оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        email: [
          { required: true, message: "Мэйл хаяг оруулна уу", trigger: "blur" },
          {
            type: "email",
            message: "шаардлага хангахгүй мэйл хаяг байна",
            trigger: ["blur", "change"]
          }
        ],
        desc: [
          {
            required: true,
            message: "Зассан шалтгаанаа оруулна уу.",
            trigger: "blur"
          }
        ],
        private_address: [
          {
            required: true,
            message: "Харилцагчийн хувийн хаягийг оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        main_category_mon: [
          {
            required: true,
            message: "Үндсэн ангилал сонгоогүй байна.",
            trigger: "blur"
          }
        ],
        main_category_eng: [
          {
            required: true,
            message: "Үндсэн ангилал сонгоогүй байна.",
            trigger: "change"
          }
        ],
        menu_categories: [
          {
            required: true,
            message: "Дотоод ангилал сонгоогүй байна.",
            trigger: "blur"
          }
        ],
        client_category: [
          {
            required: true,
            message: "Зэрэглэл сонгоогүй байна.",
            trigger: "blur"
          }
        ],
        badge_start_date: [
          {
            required: true,
            message: "Үргэлжлэх хугацаа оруулна уу",
            trigger: ["blur", "change"]
          }
        ],
        badge_end_date: [
          {
            required: true,
            message: "Үргэлжлэх хугацаа оруулна уу",
            trigger: ["blur", "change"]
          }
        ]
      },
      outletform: {
        is_delivery: true,
        is_pickup: false,
        is_draft: true,
        is_active: false,
        service_list: [],
        service: "",
        full_name_mon: "",
        full_name_eng: "",
        address: "",
        description: "",
        img_url: "",
        logo_url: "",
        email: "",
        device_name: "",
        settlement_delay_count: "",
        main_category_mon: [],
        main_category_eng: [],
        search_category_mon: [],
        search_category_eng: [],
        price_levels: [],
        phone: "",
        desc: " ",
        badge_start_date: null,
        is_new_delivery_order: false,
        badge_end_date: null,
        settlement_count: 0,
        private_address: "",
        phone_list: [],
        is_verified: false,
        is_delivery_free: false,
        is_delivery_by_outlet: false,
        child_outlets: [],
        outlet_delivery_time: 0,
        outlet_delivery_amount: 0,
        is_preOrder: false,
        is_special_home: false,
        owner_data: {}
      },
      selectBadge: false,
      BadgesList: [],
      outletFormBadge: {
        name_mon: null,
        name_eng: null,
        color: null
      },
      checkList: ["", ""],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      dynamicTags1: [],
      // inputVisible1: false,
      // inputValue1: "",
      priceLevel: [1, 1],
      priceMarks: {
        1: "$",
        2: "$$",
        3: "$$$",
        4: "$$$$",
        5: "$$$$$"
      },
      drawIndex: "",
      role: [],
      menuCategoryList: [],
      ownerList: [],
      settlement_period_data: [
        {
          label: "3 хоног",
          value: 3
        },
        {
          label: "5 хоног",
          value: 5
        },
        {
          label: "8 хоног",
          value: 8
        }
      ]
    };
  },
  watch: {
    sortData() {
      this.sortData.forEach((element, index) => {
        element.sort_grocery_number = index + 1;
      });
    }
  },
  methods: {
    formatStartDate(dateString) {
      if (!dateString) return null;

      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day} 00:00:00`;
    },
    handleClick(type) {
      this.is_new_delivery = type;
    },
    updatePartnersGroceryDeliveryType() {
      service
        .updatePartnersGroceryDeliveryType(this.sortData)
        .then(response => {
          if (response.data.status === "unsuccess") {
            this.$notify({
              title: "Алдаа гарлаа",
              message: response.data.message,
              type: "error"
            });
          }
        });
    },
    getPartnersForGrocerySort() {
      service.getPartnersForGrocerySort().then(response => {
        if (response.data.status === "success") {
          this.sortData = response.data.data;
          const tempObj = this.sortData.find(
            element => element._id === this.outletform._id
          );
          if (!tempObj) {
            this.sortData.push({
              full_name_eng: this.outletform.full_name_eng,
              full_name_mon: this.outletform.full_name_mon,
              sort_grocery_number: this.sortData.lastIndexOf() + 1,
              _id: this.outletform._id
            });
          }
        } else {
          this.sortData = [];
        }
      });
    },
    allowDrop(draggingNode, dropNode, type) {
      const typeRule = type === "prev" || type === "next";
      return draggingNode.level > dropNode.level &&
        draggingNode.parent.data.full_name_mon === "Root"
        ? !typeRule
        : typeRule;
    },
    allowDrag(draggingNode) {
      return (
        draggingNode.data.full_name_mon.indexOf("Level three 3-1-1") === -1
      );
    },
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "Харилцагчийн мэдээллийг татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    changeBadge() {
      this.BadgesList.forEach(element1 => {
        if (this.outletFormBadge.name_mon === element1.name_mon) {
          this.outletFormBadge.name_eng = element1.name_eng;
          this.outletFormBadge.color = element1.color;
        }
      });
    },
    getBadges() {
      service.getBadges().then(res => {
        this.BadgesList = res.data.data.rows;
      });
    },
    categoryChange() {
      this.outletform.main_category_eng = [];
      this.outletform.main_category_mon.forEach(element => {
        this.mainCategoryList.forEach(element1 => {
          if (element === element1.name_mon) {
            this.outletform.main_category_eng.push(element1.name_eng);
          }
        });
      });
    },
    searchCategoryChange() {
      this.outletform.search_category_eng = [];
      this.outletform.search_category_mon.forEach(element => {
        this.searchCategoryList.forEach(element1 => {
          if (element === element1.name_mon) {
            this.outletform.search_category_eng.push(element1.name_eng);
          }
        });
      });
    },
    searchCategoryChange1() {
      this.outletform.search_category_mon = [];
      this.outletform.search_category_eng.forEach(element => {
        this.searchCategoryList.forEach(element1 => {
          if (element === element1.name_eng) {
            this.outletform.search_category_mon.push(element1.name_mon);
          }
        });
      });
    },
    checkMove: function(e) {
      this.drawIndex = "Future index: " + e.draggedContext.futureIndex;
    },
    nextpage() {
      this.$router.push({
        path: "partner-address-hexagon",
        query: {
          _id: this.$route.query._id
        }
      });
    },
    onChangeImage(file) {
      this.loadUpload = true;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("Зурагны хэмжээ ихдээ 1MB байх ёстой!");
        this.imageNull = "none";
        this.loadUpload = false;
      } else {
        if (!this.outletform.full_name_eng) {
          this.$message.error("Зурагны aнгли нэрийг заавал оруулах ёстой!");
          this.imageNull = "none";
          this.loadUpload = false;
        } else {
          let payload = {};
          payload.file = file.base64;
          payload.outlet_name = this.outletform.full_name_eng;
          payload.menu_name = "Banner";
          service.uploadBanner(payload).then(res => {
            this.outletform.img_url = res.img_url;
            this.loadUpload = false;
          });
        }
      }
      return isLt2M;
    },
    onChangePickUpImage(file) {
      this.loaderUpload = true;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("Зурагны хэмжээ ихдээ 1MB байх ёстой!");
        this.imageLogo = "none";
        this.loaderUpload = false;
      } else {
        let payload = {};
        payload.file = file.base64;
        payload.outlet_name = this.outletform.full_name_eng;
        payload.menu_name = "Banner";
        service.uploadBanner(payload).then(res => {
          this.outletform.logo_url = res.img_url;
          this.loaderUpload = false;
        });
      }
      return isLt2M;
    },
    onChangeServices() {
      this.isRenderNewDesign = false;
      this.allServices.map(el => {
        if (el.eng == this.outletform.service) {
          this.outletform.delivery_avg_time = el.promised_time;
        }
      });
      this.generateIsRenderNewDesign();
      this.getMenuCategories();
      this.getMainCategories();
    },
    getMainCategories() {
      service
        .getMainCategories({ service_list: this.outletform.service_list })
        .then(categoriesResponse => {
          this.mainCategoryList = categoriesResponse.data.data;
        });
    },
    getMenuCategories() {
      service
        .getMenuCategories(
          this.outletform.service_list.toString(),
          "",
          "outlet"
        )
        .then(categoriesResponse => {
          if (categoriesResponse.data.status === "success") {
            this.menuCategoryList = [];
            let tempData = categoriesResponse.data.data;
            tempData.sort((a, b) =>
              a.menu_category_mon > b.menu_category_mon ? 1 : -1
            );
            JSON.parse(JSON.stringify(this.servicesList)).forEach(el => {
              this.menuCategoryList.push({ service: el.name_eng, options: [] });
            });
            this.menuCategoryList.forEach(element => {
              element.options = tempData.filter(
                el => el.service === element.service
              );
            });
          }
        });
    },
    getSearchCategories() {
      service.getSearchCategories().then(res => {
        this.searchCategoryList = res.data.data;
      });
    },
    async getOutletById(id) {
      this.openFullScreenLoader(true);
      const payload = { includeFields: [] };
      await service.getOutletById(id, payload).then(outletResponse => {
        this.outletform = outletResponse.data.data;
        this.is_new_delivery = outletResponse.is_new_delivery_order;
        this.getMainCategories();
        this.getMenuCategories();
        if (outletResponse.data.data.badge !== null) {
          this.outletFormBadge.name_mon =
            outletResponse.data.data.badge.name_mon;
          this.outletFormBadge.name_eng =
            outletResponse.data.data.badge.name_eng;
          this.outletFormBadge.color = outletResponse.data.data.badge.color;
          this.badge_start_date = outletResponse.data.data.badge_start_date;
          this.badge_end_date = outletResponse.data.data.badge_end_date;
          this.selectBadge = true;
        }
        if (checkProperty(outletResponse.data.data, "price_levels")) {
          if (outletResponse.data.data.price_levels !== null) {
            if (outletResponse.data.data.price_levels.length === 1) {
              this.priceLevel = [
                outletResponse.data.data.price_levels[0],
                outletResponse.data.data.price_levels[0]
              ];
            } else if (
              outletResponse.data.data.price_levels.length === 2 &&
              outletResponse.data.data.price_levels[0] === null
            ) {
              this.priceLevel = [1, 1];
            } else {
              this.priceLevel = [
                outletResponse.data.data.price_levels[0],
                outletResponse.data.data.price_levels[
                  outletResponse.data.data.price_levels.length - 1
                ]
              ];
            }
          }
        }
        if (outletResponse.data.data) {
          this.getServices();
        }
        this.openFullScreenLoader(false);
      });
    },
    getServices() {
      service.getServices(false, "active", true).then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
        this.allServices = servicesResponse.data.data;
        this.generateIsRenderNewDesign();
      });
    },
    generateIsRenderNewDesign() {
      if (this.outletform.service_list.length > 0) {
        this.outletform.service_list.forEach(element => {
          this.allServices.forEach(service => {
            if (service.name_eng === element && service.is_render_new_design) {
              this.isRenderNewDesign = true;
            }
          });
        });
      }
    },
    handleCloseMenuCategoryDrag(tag) {
      const index1 = this.outletform.menu_categories.findIndex(cat => {
        return cat.menu_category_mon === tag.menu_category_mon;
      });
      if (index1 > -1) {
        this.outletform.menu_categories.splice(index1, 1);
      }
    },
    handleInputConfirmMenuCategoryMon() {
      let inputValue = this.inputValue;
      if (inputValue) {
        if (!this.outletform.menu_category_mon) {
          this.outletform.menu_category_mon = [];
        }
        this.outletform.menu_category_mon.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    handleCloseMenuCategoryEng(tag) {
      const index1 = this.outletform.menu_category_eng.indexOf(tag);
      if (index1 > -1) {
        this.outletform.menu_category_eng.splice(index1, 1);
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    goPartnerAddress() {
      this.$refs.outletform.validate(valid => {
        if (valid) {
          this.outletform.price_levels = this.priceLevel;
          this.outletform.settlement_count = parseInt(
            this.outletform.settlement_count
          );
          if (this.outletform.phone_list.length < 4) {
            return this.$notify.error({
              title: "Error",
              message: "Утасны дугаарын жагсаалт гэсэн хэсгийг бөглөх ёстой!!!"
            });
          }
          if (this.outletform.phone_list.length >= 4) {
            if (
              this.outletform.phone_list.find(
                el => el.phone === null || el.phone === ""
              )
            ) {
              return this.$notify.error({
                title: "Error",
                message: "Утасны дугаар бичээгүй байна!!!"
              });
            }
            if (
              this.outletform.phone_list.find(
                el => el.email === null || el.email === ""
              )
            ) {
              return this.$notify.error({
                title: "Error",
                message: "Цахим хаяг бичээгүй байна!!!"
              });
            }
          } else {
            return this.$notify.error({
              title: "Error",
              message:
                "Утасны дугаарын жагсаалт гэсэн хэсгийг бүгдийг нь бөглөх ёстой!!!"
            });
          }
          if (this.$route.query._id === null) {
            getUserName().then(name => {
              this.outletform.user = name.attributes.email.split("@")[0];
              if (this.selectBadge === false) {
                this.outletFormBadge = null;
                this.outletform.badge_start_date = null;
                this.outletform.badge_end_date = null;
                this.outletform.badge = this.outletFormBadge;
                if (
                  this.outletform.is_pickup === true &&
                  (this.outletform.logo_url === "" ||
                    this.outletform.logo_url === null)
                ) {
                  return this.$notify.error({
                    title: "Error",
                    message: "Лого оруулна уу!!!"
                  });
                }
                if (this.outletform.child_outlets.length > 0) {
                  let tempOutlets = [];
                  this.outletform.child_outlets.map(el => {
                    if (!el.outlet_id) {
                      el.outlet_id = el._id;
                    }
                    tempOutlets.push(el);
                  });
                  this.outletform.child_outlets = JSON.parse(
                    JSON.stringify(tempOutlets)
                  );
                }
                service.addPartner(this.outletform).then(response => {
                  this.$router.push({
                    path: "partner-address-hexagon",
                    query: { _id: response.data._id }
                  });
                });
              } else {
                if (this.outletFormBadge.name_mon == null) {
                  this.$notify.error({
                    title: "Error",
                    message: "Бадже сонгоно уу"
                  });
                } else {
                  this.outletform.badge = this.outletFormBadge;
                  service.addPartner(this.outletform).then(response => {
                    this.$router.push({
                      path: "partner-address-hexagon",
                      query: { _id: response.data._id }
                    });
                  });
                }
              }
            });
          } else {
            this.outletform._id = this.$route.query._id;
            getUserName().then(name => {
              this.outletform.user = name.attributes.email.split("@")[0];
              if (
                this.outletform.is_pickup === true &&
                (this.outletform.logo_url === "" ||
                  this.outletform.logo_url === null)
              ) {
                return this.$notify.error({
                  title: "Error",
                  message: "Лого оруулна уу!!!"
                });
              }
              if (this.selectBadge === false) {
                this.outletFormBadge = null;
                this.outletform.badge_start_date = null;
                this.outletform.badge_end_date = null;
                this.outletform.badge = this.outletFormBadge;
                if (
                  this.outletform.desc == "" ||
                  this.outletform.desc == null
                ) {
                  this.outletform.desc = "hooson bn";
                } else {
                  this.outletform.desc = this.outletform.desc;
                }
                if (this.outletform.child_outlets.length > 0) {
                  let tempOutlets = [];
                  this.outletform.child_outlets.map(el => {
                    if (!el.outlet_id) {
                      el.outlet_id = el._id;
                    }
                    tempOutlets.push(el);
                  });
                  this.outletform.child_outlets = JSON.parse(
                    JSON.stringify(tempOutlets)
                  );
                }
                this.outletform.preorder_start_date = this.formatStartDate(
                  this.outletform.preorder_start_date
                );
                this.outletform.preorder_end_date = this.formatStartDate(
                  this.outletform.preorder_end_date
                );
                service
                  .editPartner(this.$route.query._id, this.outletform)
                  .then(response => {
                    if (
                      this.outletform.service_list.includes("grocery") &&
                      this.outletform.is_new_delivery_order &&
                      this.outletform.grocery_delivery_type === "chain_store"
                    ) {
                      this.updatePartnersGroceryDeliveryType();
                    }
                    this.$router.push({
                      path: "partner-address-hexagon",
                      query: { _id: this.$route.query._id }
                    });
                    this.$notify({
                      title: "Success",
                      message: response.data === "" ? "амжилттай" : "амжилттай",
                      type: "success"
                    });
                  });
              } else {
                if (this.outletFormBadge.name_mon == null) {
                  this.$notify.error({
                    title: "Error",
                    message: "Бадже сонгоно уу"
                  });
                } else {
                  this.outletform.badge = this.outletFormBadge;
                  if (
                    this.outletform.desc == "" ||
                    this.outletform.desc == null
                  ) {
                    this.outletform.desc = "hooson bn";
                  } else {
                    this.outletform.desc = this.outletform.desc;
                  }
                  if (this.outletform.child_outlets.length > 0) {
                    let tempOutlets = [];
                    this.outletform.child_outlets.map(el => {
                      if (!el.outlet_id) {
                        el.outlet_id = el._id;
                      }
                      tempOutlets.push(el);
                    });
                    this.outletform.child_outlets = JSON.parse(
                      JSON.stringify(tempOutlets)
                    );
                  }
                  if (
                    this.outletform.is_pickup === true &&
                    (this.outletform.logo_url === "" ||
                      this.outletform.logo_url === null)
                  ) {
                    return this.$notify.error({
                      title: "Error",
                      message: "Лого оруулна уу!!!"
                    });
                  }
                  service
                    .editPartner(this.$route.query._id, this.outletform)
                    .then(response => {
                      if (response.data.status === "success") {
                        if (
                          this.outletform.service_list.includes("grocery") &&
                          this.outletform.is_new_delivery_order &&
                          this.outletform.grocery_delivery_type ===
                            "chain_store"
                        ) {
                          this.updatePartnersGroceryDeliveryType();
                        }
                        this.$notify({
                          title: "Амжилттай",
                          message: response.data.data.message,
                          type: "success"
                        });
                      } else {
                        this.$notify({
                          title: "Алдаа гарлаа",
                          message: response.data.message,
                          type: "warning"
                        });
                      }
                    });
                }
              }
            });
          }
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    pickUp(data) {
      if (data === true) {
        this.outletform.is_preOrder = true;
      }
    },
    getOwnerList() {
      service.getOwnerList().then(ownerList => {
        this.ownerList = ownerList.data.data;
      });
    }
  }
};
</script>
